
import { Vue, Component, ModelSync } from "vue-property-decorator";

import moment from "moment";

@Component
export default class CalendarInput extends Vue {
    @ModelSync("dateValue", "change", { type: String })
    readonly date?: string;

    /**
     * Computed
     */

    get today() {
        return new Date().toISOString().split("T")[0];
    }
    get formatDate() {
        if (this.date) {
            return moment(this.date).format("DD/MM/YYYY");
        }
        return "";
    }
}
